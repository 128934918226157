<template>
  <a-table :columns="columns" :source="courseSections" :loading="loading">
    <template #toolbar(search)>
      <h4 class="text-2xl p-2">Materias</h4>
    </template>
    <template #toolbar>
      <a-period-accessor v-model="period" class="mr-2" />
    </template>
    <template #td(actions)="{ item }">
      <a
        class="px-4 py-2 rounded-xl active:scale-95 transform transition-transform hover:bg-primary hover:bg-opacity-10 cursor-pointer"
        @click="$router.push({ name: 'lessons.studentsNotes', params: { section_id: item.section_id, course_id: item.course_id }})"
        rel="noreferrer"
        target="_blank">
        Ver carpetón
      </a>
    </template>
  </a-table>
</template>

<script>
import {mapState} from "vuex";

export default {
  data: () => ({
    period: null,
    loading: false,
    courseSections: [],
    coordinate_specializations: [],
    columns: [
      { title: 'Materia', key: 'course.title' },
      { title: 'Especialidad', key: 'specialization.title', alignment: 'center' },
      // { title: 'Semestre', key: 'semester.number', alignment: 'center' },
      { title: 'Sección', key: 'section.letter_code' },
      { title: 'Acciones', key: '', slot: 'actions' }
    ],
    query: {
      limit: 0,
      with: 'semester',
      sort_by: 'specialization,asc'
    }
    // TODO Este Sort no está haciendo nada
  }),
  computed: {
    ...mapState({
      user: ({ session }) => (session.user)
    })
  },
  methods: {
    fetch (period) {
      if(this.user.isCoordinator){
        this.$repository.teachers
          .coordinateSpecializations(this.user.teacher.id)
          .then(({ data }) => {
  
            this.coordinate_specializations = data;
            
          })
      }

      this.$repository.teachers
        .courses(this.$route.params.id, { period, ...this.query })
        .then(({ data }) => {
          this.courseSections = this.mappedCourseSection(data)
        })
        ._loading(() => this.loading = !this.loading)

    },
    mappedCourseSection(data) {
      if (this.user.isCoordinator) {
        return data.flatMap(it => this.coordinate_specializations.find(id => id.id === it.section.specialization_id) ? it : []).sort((a, b) => a.date - b.date)
      } else {
        return data
      }
    }
  },
  watch: {
    period(period) {
      if (period) {
        this.fetch(period)
      }
    }
  }
}
</script>
